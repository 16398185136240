@font-face {
  font-family: 'tke_icons';
  src:  url('../assets/fonts/icons/tke.woff') format('woff');
  font-weight:  normal;
  font-style:  normal; }

@font-face {
  font-family: tktyperegular;
  font-style: normal;
  font-weight: 400;
  src: url(https://ucpcdn.thyssenkrupp.com/fonts/tktyperegular-regular.woff2) format("woff2"),url(https://ucpcdn.thyssenkrupp.com/fonts/tktyperegular-regular.woff) format("woff");
  font-display: swap; }

@font-face {
  font-family: tktypeitalic;
  font-style: italic;
  font-weight: 400;
  src: url(https://ucpcdn.thyssenkrupp.com/fonts/tktyperegular-italic.woff2) format("woff2"),url(https://ucpcdn.thyssenkrupp.com/fonts/tktyperegular-italic.woff) format("woff");
  font-display: swap; }

@font-face {
  font-family: tktypemedium;
  font-style: normal;
  font-weight: 500;
  src: url(https://ucpcdn.thyssenkrupp.com/fonts/tktypemedium-regular.woff2) format("woff2"),url(https://ucpcdn.thyssenkrupp.com/fonts/tktypemedium-regular.woff) format("woff");
  font-display: swap; }

@font-face {
  font-family: tktypebold;
  font-style: normal;
  font-weight: 700;
  src: url(https://ucpcdn.thyssenkrupp.com/fonts/tktypebold-regular.woff2) format("woff2"),url(https://ucpcdn.thyssenkrupp.com/fonts/tktypebold-regular.woff) format("woff");
  font-display: swap; }
