@charset "utf-8";

@import "../../styles/lib/mixins.sass";
@import "../../styles/lib/variables.sass";

































































.overlay-segment {
  position: fixed;
  visibility: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  background-color: black();
  transition: opacity .5s ease;
  z-index: $z-index-overlay;
  &.is-overlay-slider {
    z-index: $z-index-slider - 10; }
  &.is-visible {
    visibility: visible;
    opacity: .7; } }
