@charset "utf-8";

@import "../../styles/lib/mixins.sass";
@import "../../styles/lib/variables.sass";

























































































































// Minimum style for classes is-vertical and is-horizontal
// usually styled by parent component
.links-elem {
  &.is-vertical {
    .links-elem-level-2 {
      .item {
        margin-left: m(3); } } }
  &.is-horizontal {
    display: flex;
    .item {
      margin-right: m(1);
      &:last-child {
        margin-right: 0; } }
    .links-elem-level-2 {
      display: none; } } }
