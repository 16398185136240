@charset "utf-8";

@import "../../styles/lib/mixins.sass";
@import "../../styles/lib/variables.sass";




























































































































.sections-elem {
  .section {
    margin-top: m(2);
    &:first-child {
      margin-top: 0; } } }

@include md {
  .sections-elem {} }

@include sm {
  .sections-elem {
    .section {
      margin-top: m(3);
      &:first-child {
        margin-top: 0; } } } }

@include xs {
  .sections-elem {
    .section {
      margin-top: m(2); } } }
