@charset "utf-8";

@import "../../styles/lib/mixins.sass";
@import "../../styles/lib/variables.sass";






















































.product-segment {
  background-color: white();
  .product-select {
    display: flex;
    align-items: center;
    .svg-elem {
      fill: $primary-color;
      width: m(7); }
    ul {
      display: flex;
      li {
        margin: 0 m(1.5);
        .link {
          @include font('medium', 'semibold');
          color: $primary-color; }
        &.is-active {
          .link {
            text-decoration: underline; } } }
      &.escalators {
        margin-right: m(4); }
      &.movingwalks {
 } } }        //margin-right: - m(2)
  .actions {
    border-top: 2px solid grey(6); }

  @include sm;
  .product-segment {
    .product-select {
      display: none; } } }
