@charset "utf-8";

@import "../../styles/lib/mixins.sass";
@import "../../styles/lib/variables.sass";




























































.image-elem {
  // no display: flex !!
  font-size: 0;
  line-height: 0;
  &.is-hero {
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;
    img {
      flex: 1;
      font-family: "object-fit:cover;object-position:50% 50%";
      object-fit: cover;
      object-position: 50% 50%;
      width: 100%;
      height: auto; } } }
