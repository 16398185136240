@charset "utf-8";

@import "../../styles/lib/mixins.sass";
@import "../../styles/lib/variables.sass";










































































































































































































































































































// on-canvas
$nav-main-color:                 white();
$nav-main-hover-color:           white();
$nav-main-active-color:          white();

$nav-meta-color:                 grey(5);
$nav-meta-hover-color:           $link-active-color;
$nav-meta-active-color:          $link-active-color;

// dropdowns on-canvas navigation
$nav-dropdown-color:             grey(4);
$nav-dropdown-bg-color:          grey(7);
$nav-dropdown-hover-color:       darken(grey(7), 5%);

// for off-canvas slider
$nav-slider-bg-color:            white();
$nav-slider-border-color:        grey(7);

$nav-slider-main-bg-color:       white();
$nav-slider-main-color:          $primary-color;
$nav-slider-main-hover-color:    $primary-color;
$nav-slider-main-active-color:   $primary-color;

$nav-slider-meta-bg-color:       white();
$nav-slider-meta-color:          grey(3);
$nav-slider-meta-hover-color:    grey(3);
$nav-slider-meta-active-color:   grey(3);

// the off-canvas open button
$nav-button-bg-color:            $content-color;
$nav-button-closed-color:        grey(5); // closed status
$nav-button-open-color:          grey(5); // open status

.navigation-elem {
  &.on-init {
    visibility: hidden; }
  &.on-canvas {
    position: relative;
    .nav-slider {
      .nav-viewbox {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        .main-group {
          display: flex;
          justify-content: center;
          margin-left: - m(1);
          margin-right: - m(1);
          .links-elem-level-1 {
            position: relative;
            display: flex;
            line-height: 1;
            >.item {
              display: flex;
              flex-direction: column;
              padding: 0 m(1);
              >.link {
                display: flex;
                align-items: center;
                position: relative;
                @include font('semibold');
                line-height: 2;
                color: $nav-main-color;
                white-space: nowrap;
                &:hover {
                  color: $nav-main-hover-color;
                  text-decoration: underline; } }
              &.is-hidden-oncanvas {
                display: none; }
              &.has-dropdown {
                .links-elem-level-2 {
                  display: flex;
                  visibility: hidden;
                  position: absolute;
                  flex-direction: column;
                  top: 100%;
                  padding: m(1) 0;
                  background-color: $nav-dropdown-bg-color;
                  z-index: $z-index-base + 10;
                  @include soft-shadow;
                  &:before {
                    position: absolute;
                    top: -5px;
                    content: "";
                    border-bottom: 5px solid $nav-dropdown-bg-color;
                    border-left: 5px solid transparent;
                    border-right: 5px solid transparent; }
                  &.is-left {
                    margin-left: 0;
                    &:before {
                      left: m(4); } }
                  &.is-right {
                    align-self: flex-end;
                    margin-right: - m(1);
                    &:before {
                      right: m(4); } }
                  >.item {
                    padding: 0;
                    >.link {
                      display: flex;
                      padding: m(1) m(2);
                      color: $nav-dropdown-color;
                      white-space: nowrap;
                      &:hover {
                        color: $nav-dropdown-color;
                        background-color: $nav-dropdown-hover-color; } }
                    &.is-active {
                      >.link {
                        background-color: $nav-dropdown-hover-color; } } } } }
              &.is-open { // dropdown is open
                &.is-active {
                  &:after {
                    background-color: transparent; } }
                .links-elem-level-2 {
                  visibility: visible; } } } }
          .item-lang {
            .language-select-elem {
              display: inline-block;
              margin-left: m(1);
              margin-right: m(1);
              .select {
                width: auto;
                padding: 0;
                @include font('default', 'semibold');
                line-height: 2;
                color: $nav-main-color;
                border: none;
                background-color: transparent;
                .dropdown-icon {
                  border-color: $nav-main-color;
                  border-left-width: 3px;
                  border-bottom-width: 3px;
                  margin-top: -4px; } } } } } } } }

  &.off-canvas {
    .nav-toolbar {
      display: inline-block;
      width: auto;
      padding: 0;
      background-color: transparent;
      line-height: 1; }
    .nav-slider {
      $min-width: map-get($breakpoints, 'xs') * .75;
      visibility: hidden;
      position: fixed;
      top: 0;
      right: 0;
      height: 100%;
      width: auto;
      min-width: #{$min-width}px;
      max-width: 100%;
      background-color: $nav-slider-bg-color;
      z-index: $z-index-slider + 10;
      transform: translateX(100%);
      transition: transform .5s ease 0s, visibility 0s linear .5s;
      @include soft-shadow;
      .nav-viewbox {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow-x: hidden;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        .close-button {
          flex-shrink: 2;
          align-self: flex-start;
          margin: m(.5) m(2) 0 m(2); }
        .item {
          .link {
            display: block;
            position: relative;
            padding: m(.5) m(4);
            cursor: pointer;
            @include ellipsis; }
          &.has-dropdown {
            >.link {
              display: flex;
              flex-direction: row-reverse;
              justify-content: flex-end;
              align-items: center;
              @include icon('down');
              &:before {
                margin-left: 4px;
                font-size: .6em; } }
            .links-elem-level-2 {
              visibility: hidden;
              height: 0;
              .item {
                .link {
                  padding-left: m(6); } } }
            &.is-open {
              .links-elem-level-2 {
                visibility: visible;
                height: 100%; } } } }
        .main-group {
          display: flex;
          flex-direction: column;
          border-bottom: 1px solid $nav-slider-border-color;
          .links-elem-level-1 {
            background-color: $nav-slider-main-bg-color;
            .item {
              line-height: 2;
              border-top: 1px solid $nav-slider-border-color;
              .link {
                color: $nav-slider-main-color;
                &:hover {
                  color: $nav-slider-main-hover-color;
                  text-decoration: underline; } }
              >.link {
                &:before {
                  transform: rotate(-90deg);
                  transition: transform .1s linear; } }
              &.is-open {
                >.link {
                  &:before {
                    transform: translateY(2px) rotate(0deg); } } }
              &.is-active {
                >.link {
                  color: $nav-slider-main-active-color; }
                &.is-open {
                  >.link {
                    color: $nav-slider-main-color; } } } } }
          .item-lang {
            padding-top: 10px;
            padding-bottom: 0;
            border-top: 1px solid $nav-slider-border-color;
            .language-select-elem {
              display: inline-block;
              margin-left: m(4);
              margin-bottom: m(1);
              .select {
                width: auto;
                padding: 0;
                @include font('default', 'regular');
                color: $nav-slider-main-color;
                border: none;
                background-color: transparent;
                .dropdown-icon {
                  border-color: $nav-slider-main-color;
                  border-left-width: 3px;
                  border-bottom-width: 3px;
                  margin-top: -4px; } } } } } }
      &.is-open {
        visibility: visible;
        transform: translateX(0);
        transition: transform .5s ease 0s; } } } }

