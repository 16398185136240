@charset "utf-8";

@import "../../styles/lib/mixins.sass";
@import "../../styles/lib/variables.sass";




































































































































































$dialog-width: 620px;
.dialog-segment {
  position: fixed;
  display: none;
  flex-direction: column;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: $z-index-modal;
  .close-btn {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 30px;
    background-color: white();
    border-radius: 100%;
    border: 1px solid grey(5);
    cursor: pointer;
    span {
      position: absolute;
      display: block;
      top: calc(50% - 2px);
      left: 10%;
      height: 4px;
      width: 80%;
      background-color: grey(5);
      border-radius: 2px;
      &:nth-child(1) {
        transform: rotate(45deg); }
      &:nth-child(2) {
        transform: rotate(-45deg); } }
    &:focus,
    &:active {
      border-color: grey(4);
      span {
        background-color: grey(4); } } }
  &.is-dialog {
    justify-content: center;
    .viewbox {
      display: flex;
      flex-direction: column;
      flex-shrink: 1;
      max-width: $dialog-width;
      margin: 0 auto;
      background-color: white();
      .close {
        position: relative;
        display: flex;
        justify-content: flex-end;
        line-height: 0;
        .close-btn {
          position: absolute;
          transform: translate(10px, -10px); } }
      .body {
        padding: m(2);
        text-align: center;
        @include font('tool');
        * {
          @include font('tool'); } }
      .spinner {
        line-height: 0;
        padding-bottom: m(3);
        &.has-actions {
          padding-bottom: m(1); } }
      .actions {
        display: flex;
        justify-content: center;
        padding-bottom: m(2);
        .button-elem {
          margin: 0 m(1); } } } }
  &.is-fullscreen {
    justify-content: flex-start;
    .viewbox {
      position: relative;
      max-width: content-width('sm');
      height: 100%;
      max-height: 100%;
      margin: 0 auto;
      background-color: white();
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
      .close {
        position: relative;
        display: flex;
        justify-content: flex-end;
        line-height: 0;
        padding: m(2) m(2) 0 0;
        .close-btn {
          position: fixed; } }
      .body {
        padding: m(4) content-padding('md'); }
      .spinner {
        padding: m(4) content-padding('md');
        line-height: 0;
        padding-bottom: m(3);
        &.has-actions {
          padding-bottom: m(1); } }
      .actions {
        display: flex;
        justify-content: center;
        padding: m(4) content-padding('md');
        .button-elem {
          margin: 0 m(1); } } } }
  &.is-error {
    .viewbox {
      border-top: 10px solid $warning-color;
      .close {
        .close-btn {
          transform: translate(10px, -24px); } } } }
  &.is-visible {
    display: flex; } }

@include sm {
  .dialog-segment {
    &.is-fullscreen {
      .viewbox {
        .body,
        .actions {
          padding: m(4) content-padding('sm'); } } } } }

@include xs {
  .dialog-segment {
    &.is-fullscreen {
      .viewbox {
        .body,
        .actions {
          padding: m(4) content-padding('xs'); } } } } }

@include until($dialog-width) {
  .dialog-segment {
    &.is-dialog {
      justify-content: flex-start;
      background-color: white;
      .viewbox {
        .close {
          padding: m(2) m(2) 0 0;
          .close-btn {
            position: relative;
            transform: none; } } } } } }
