// col is-3:        is 3 grids of 12-grid
// col is-2-from-5: is 2/5th of width

//--------------------------------------------------------------------------
// Structure
//--------------------------------------------------------------------------

.template { // root div of page
  z-index: $z-index-base; }

.segment {
 }  // fullwidth, no padding or margin on default

.container {
  margin: 0 auto;
  padding: 0 content-padding();
  width: content-width();
  max-width: 100%;
  &.is-fullwidth {
    width: 100%; } }

@include md {
  .container {
    padding: 0 content-padding('md');
    width: content-width('md');
    &.is-fullwidth {
      padding-right: 0;
      padding-left: 0; } } }

@include sm {
  .container {
    padding: 0 content-padding('sm');
    width: content-width('sm');
    &.is-fullwidth {
      padding-right: 0;
      padding-left: 0; } } }

@include xs {
  .container {
    padding: 0 content-padding('xs');
    width: content-width('xs');
    &.is-fullwidth {
      padding-right: 0;
      padding-left: 0; } } }

//--------------------------------------------------------------------------
// Visibility
//--------------------------------------------------------------------------

.is-hidden {
  display: none !important; }

@include lg-only {
  .is-hidden-lg-only,
  .is-md-only,
  .is-sm-only,
  .is-xs-only {
    display: none !important; } }

@include md {
  .is-hidden-md {
    display: none !important; } }

@include md-only {
  .is-lg-only,
  .is-hidden-md-only,
  .is-sm-only,
  .is-xs-only {
    display: none !important; } }

@include sm {
  .is-hidden-sm {
    display: none !important; } }

@include sm-only {
  .is-lg-only,
  .is-md-only,
  .is-hidden-sm-only,
  .is-xs-only {
    display: none !important; } }

@include xs {
  .is-hidden-xs {
    display: none !important; } }

@include xs-only {
  .is-lg-only,
  .is-md-only,
  .is-sm-only,
  .is-hidden-xs-only {
    display: none !important; } }

//--------------------------------------------------------------------------
// Grid system
//--------------------------------------------------------------------------

.row {
  display: flex;
  flex-direction: row;
  margin-left: -(col-gap() / 2);
  margin-right: -(col-gap() / 2);
  &.is-row {
    flex-direction: row; }
  &.is-row-reverse {
    flex-direction: row-reverse; }
  &.is-column {
    flex-direction: column; }
  &.is-column-reverse {
    flex-direction: column-reverse; }
  &.is-box { // allowes row to have a background, that is not wider than content
    margin-left: 0;
    margin-right: 0; }
  .col {
    display: block;
    flex-grow: 1;
    flex-shrink: 1;
    padding: col-gap() / 2;
    @for $i from 1 through $col-count {
      &.is-#{$i} {
        flex: none;
        width: percentage($i / $col-count); }
      &.is-offset-#{$i} {
        margin-left: percentage($i / $col-count); } }
    @for $i from 1 through 10 {
      @for $j from 1 through $i {
        &.is-#{$j}-from-#{$i} {
          flex: none;
          width: percentage((10 / $i * $j) / 10); }
        &.is-offset-#{$j}-from-#{$i} {
          margin-left: percentage((10 / $i * $j) / 10); } } } }
  &.no-gap {
    margin-left: 0;
    margin-right: 0;
    >.col {
      padding: 0; }
    &.is-first {
      >.col {
        padding-top: col-gap()/2; } }
    &.is-last {
      >.col {
        padding-bottom: col-gap()/2; } } }
  &.is-multiline {
    flex-wrap: wrap; }
  &.is-centered {
    justify-content: center; }
  &.is-vcentered {
    align-items: center; }
  &.is-visible {
    display: flex; }
  &.is-hidden {
    display: none; } }

@include md {
  .row {
    margin-left: -(col-gap('md') / 2);
    margin-right: -(col-gap('md') / 2);
    .col {
      padding: col-gap('md') / 2;
      @for $i from 1 through $col-count {
        &.is-#{$i}-md {
          flex: none;
          width: percentage($i / $col-count); }
        &.is-offset-#{$i}-md {
          margin-left: percentage($i / $col-count); } }
      @for $i from 1 through 10 {
        @for $j from 1 through $i {
          &.is-#{$j}-from-#{$i}-md {
            flex: none;
            width: percentage((10 / $i * $j) / 10); }
          &.is-offset-#{$j}-from-#{$i}-md {
            margin-left: percentage((10 / $i * $j) / 10); } } }
      &.is-no-offset-md {
        margin-left: 0; } }
    &.no-gap,
    &.no-gap-md {
      margin-left: 0;
      margin-right: 0;
      >.col {
        padding: 0; }
      &.is-first {
        >.col {
          padding-top: col-gap('md')/2; } }
      &.is-last {
        >.col {
          padding-bottom: col-gap('md')/2; } } }
    &.is-multiline-md {
      flex-wrap: wrap; }
    &.is-centered-md {
      justify-content: center; }
    &.is-vcentered-md {
      align-items: center; } } }

@include sm {
  .row {
    margin-left: -(col-gap('sm') / 2);
    margin-right: -(col-gap('sm') / 2);
    .col {
      padding: col-gap('sm') / 2;
      @for $i from 1 through $col-count {
        &.is-#{$i}-sm {
          flex: none;
          width: percentage($i / $col-count); }
        &.is-offset-#{$i}-sm {
          margin-left: percentage($i / $col-count); } }
      @for $i from 1 through 10 {
        @for $j from 1 through $i {
          &.is-#{$j}-from-#{$i}-sm {
            flex: none;
            width: percentage((10 / $i * $j) / 10); }
          &.is-offset-#{$j}-from-#{$i}-sm {
            margin-left: percentage((10 / $i * $j) / 10); } } }
      &.is-no-offset-sm {
        margin-left: 0; } }
    &.no-gap,
    &.no-gap-sm {
      margin-left: 0;
      margin-right: 0;
      >.col {
        padding: 0; }
      &.is-first {
        >.col {
          padding-top: col-gap('sm')/2; } }
      &.is-last {
        >.col {
          padding-bottom: col-gap('sm')/2; } } }
    &.is-multiline-sm {
      flex-wrap: wrap; }
    &.is-centered-sm {
      justify-content: center; }
    &.is-vcentered-sm {
      align-items: center; } } }

@include xs {
  .row {
    margin-left: -(col-gap('xs') / 2);
    margin-right: -(col-gap('xs') / 2);
    &.is-box { // allowes row to have a background, that is not wider than content
      margin-left: - content-padding('xs');
      margin-right: - content-padding('xs'); }
    .col {
      padding: col-gap('xs') / 2;
      &.is-no-offset-xs {
        margin-left: 0; } }
    &:not(.is-xs) {
      flex-wrap: wrap;
      .col {
        margin-left: 0;
        width: 100%; }
      &.no-gap,
      &.no-gap-xs {
        margin-left: 0;
        margin-right: 0;
        >.col {
          padding: 0; }
        &.is-first {
          >.col:first-child {
            padding-top: col-gap('xs')/2; } }
        &.is-last {
          >.col:last-child {
            padding-bottom: col-gap('xs')/2; } } } }
    &.is-xs {
      display: flex;
      .col {
        @for $i from 1 through $col-count {
          &.is-#{$i}-xs {
            width: percentage($i / $col-count); }
          &.is-offset-#{$i}-xs {
            margin-left: percentage($i / $col-count); } }
        @for $i from 1 through 10 {
          @for $j from 1 through $i {
            &.is-#{$j}-from-#{$i}-xs {
              flex: none;
              width: percentage((10 / $i * $j) / 10); }
            &.is-offset-#{$j}-from-#{$i}-xs {
              margin-left: percentage((10 / $i * $j) / 10); } } }
        &.is-no-offset-xs {
          margin-left: 0; } }
      &.no-gap,
      &.no-gap-xs {
        margin-left: 0;
        margin-right: 0;
        >.col {
          padding: 0; }
        &.is-first {
          >.col {
            padding-top: col-gap('xs')/2; } }
        &.is-last {
          >.col {
            padding-bottom: col-gap('xs')/2; } } } }
    &.is-multiline-xs {
      flex-wrap: wrap; }
    &.is-centered-xs {
      justify-content: center; }
    &.is-vcentered-xs {
      align-items: center; } } }
