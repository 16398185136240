//--------------------------------------------------------------------------
// Styles
//--------------------------------------------------------------------------

// base settings
.button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  @include font('bold');
  border: 1px solid;
  border-radius: none;
  cursor: pointer;
  @include ellipsis;
  &:before {
    padding: 0 .2em 0 0; }
  &.pull-right {
    flex-direction: row-reverse;
    &:before {
      padding: 0 0 0 .2em; } } }

// colors / styles
.button {
  @include button-color(white(), grey(5));
  &.is-primary {
    @include button-color(white(), $primary-color, $secondary-color); }
  &.is-secondary {
    @include button-color(white(), $secondary-color); }
  &.is-warning {
    @include button-color(white(), $warning-color); }
  &.is-success {
    @include button-color(white(), $success-color); }
  &.is-white {
    @include button-color-white(); }
  &.is-text {
    @include button-color($text-color, transparent);
    &:before {
      font-size: font-size('default') * 0.8; } }
  &.has-spinner { // set by button prop "spinner = true"
    color: transparent !important;
    &:before {
      color: transparent !important; }
    .spinner-elem {
      display: inline-block;
      position: absolute;
      left: 50%;
      top: 50%;
      width: 60px;
      margin-left: -30px;
      margin-top: -10px;
      animation-delay: .2s;
      div {
        background-color: grey(5); } } } }

// sizes
.button {
  @include button-size('default');
  &.is-small {
    @include button-size('small'); }
  &.is-tool {
    @include button-size('tool'); }
  &.is-medium {
    @include button-size('medium'); }
  &.is-big {
    @include button-size('big'); }
  &.is-bigger {
    @include button-size('bigger'); } }

// fixes
.button {
  &.move-down {
    &:before {
      padding-top: .2em; } }
  &.move-up {
    &:before {
      padding-bottom: .2em; } }
  &.move-left {
    &:before {
      padding-right: .3em; } }
  &.move-right {
    &:before {
      padding-left: .3em; } } }
