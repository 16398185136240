@charset "utf-8";

@import "../../styles/lib/mixins.sass";
@import "../../styles/lib/variables.sass";














































// every color-class or fill is ignored, when svg has fill-attribute
// (which is intended to have multi-colored svgs)
.svg-elem {
  height: auto;
  fill-rule: evenodd;
  clip-rule: evenodd;
  stroke-linejoin: round;
  stroke-miterlimit: 1.41421;
  fill: grey(5);
  &.is-primary {
    fill: $primary-color; }
  &.is-secondary {
    fill: $secondary-color; }
  &.is-warning {
    fill: $warning-color; }
  &.is-success {
    fill: $success-color; }
  &.is-white {
    fill: white(); } }
