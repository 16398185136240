@charset "utf-8";

@import "../styles/lib/mixins.sass";
@import "../styles/lib/variables.sass";






































.debug-segment {
  position: fixed;
  top: 0;
  left: 0;
  padding: 2px 10px;
  color: white;
  font-size: 14px;
  background-color: red;
  z-index: 1000;
  .width {
    padding-right: 10px; }
  .breakpoint {
    display: inline-block; } }

