@charset "utf-8";

@import "../../styles/lib/mixins.sass";
@import "../../styles/lib/variables.sass";


































.footer-segment {
  z-index: $z-index-base;
  background-color: $body-color;
  margin-top: m(3);
  .nav-footer {
    display: flex;
    justify-content: flex-end;
    margin-left: - m(1);
    margin-right: - m(1);
    li {
      margin: 0 m(1);
      a {
        color: $primary-color;
        &:hover {
          text-decoration: underline; } } } } }

@include xs {
  .footer-segment {
    .col {
      display: flex;
      justify-content: center;
      padding: 0; }
    .nav-footer {
      justify-content: center;
      margin: 0; } } }
