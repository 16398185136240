//--------------------------------------------------------------------------
// Mixins
//--------------------------------------------------------------------------
//
// <p class="typo">foo</p>  -> assigns typography to single element
//
// <div class="typo">  -> assigns typography to child elements
//    <h1>bar</h1>
//    <p>foo</p>
// </div>
//
// <p class="text">foo</p>  -> assigns typography AND margins to single element
//
// <div class="text">  -> assigns typography AND margins to child elements
//    <h1>bar</h1>
//    <p>foo</p>
// </div>

@mixin reverse($list) {
  @each $elem in $list {
    .typo #{$elem} {
      @extend #{$elem}.typo; }
    .text #{$elem} {
      @extend #{$elem}.text; } } }

//--------------------------------------------------------------------------
// Text, Font, Typo
//--------------------------------------------------------------------------

.reset {
  @include font('default', 'regular');
  color: $text-color; }

h1.typo {
  @include font('bigger', 'semibold');
  color: $headline-color; }
h2.typo {
  @include font('big', 'semibold');
  color: $headline-color; }
h3.typo {
  @include font('medium', 'semibold');
  color: $headline-color; }
h4.typo,
h5.typo,
h6.typo {
  @extend .reset; }
p.typo {
  @extend .reset; }
hr.typo {
  height: 1px;
  background-color: transparent;
  background-image: linear-gradient(to right, grey(6) 60%, transparent 40%);
  background-size: m(2) 100%; }

// links
a.typo {
  color: $link-color;
  strong {
    color: currentColor; }
  &:hover {
    color: $link-active-color;
    text-decoration: underline; } }

// additional styles
strong,
.is-strong.typo,
strong.typo {
  @include font('semibold');
  * {
    @include font('semibold'); } }
.is-teaser.typo {
  @include font('medium');
  * {
    @include font('medium'); } }
.is-tool.typo {
  @include font('tool');
  * {
    @include font('tool'); } }
.is-small.typo {
  @include font('small');
  * {
    @include font('small'); } }
.is-em.typo,
em.typo {
  @include font('italic');
  * {
    @include font('italic'); } }
.is-soft.typo {
  opacity: $opacity-soft;
  * {
    opacity: $opacity-soft; } }
.is-softer.typo {
  opacity: $opacity-softer;
  * {
    opacity: $opacity-softer; } }
.block.typo {
  @extend .reset; }

// same styles with margin/padding
h1.text {
  @extend h1.typo;
  margin: m(8) 0 m(3) 0;
  &:first-child {
    margin-top: 0; }
  &:last-child {
    margin-bottom: 0; } }
h2.text {
  @extend h2.typo;
  margin: m(4) 0 m(2) 0;
  &:first-child {
    margin-top: 0; }
  &:last-child {
    margin-bottom: 0; } }
h3.text {
  @extend h3.typo;
  margin: m(2) 0 m(1) 0;
  &:first-child {
    margin-top: 0; }
  &:last-child {
    margin-bottom: 0; } }
h4.text,
h5.text,
h6.text {
  @extend h4.typo;
  margin: m(2) 0 m(1) 0;
  &:first-child {
    margin-top: 0; }
  &:last-child {
    margin-bottom: 0; } }
p.text {
  @extend p.typo;
  margin: 0 0 m(2) 0;
  &:first-child {
    margin-top: 0; }
  &:last-child {
    margin-bottom: 0; } }
hr.text {
  @extend hr.typo;
  margin: m(3) 0 m(4) 0; }

// any other div-element inside block flow
.block.text {
  @extend p.typo;
  margin: m(2) 0 m(2) 0;
  &:first-child {
    margin-top: 0; }
  &:last-child {
    margin-bottom: 0; } }

// links
a.text {
  @extend a.typo; }

// additional styles
strong.text,
.is-strong.text {
  @extend strong.typo; }
.is-teaser.text {
  @extend .is-teaser.typo; }
.is-tool.text {
  @extend .is-tool.typo; }
.is-small.text {
  @extend .is-small.typo; }
em.text,
.is-em.text {
  @extend em.typo; }
.is-soft.text {
  @extend .is-soft.typo; }
.is-softer.text {
  @extend .is-softer.typo; }

@include reverse(h1 h2 h3 h4 h5 h6 p hr strong a ".is-strong" ".is-teaser" ".is-tool" ".is-small" em ".is-em" ".is-soft" ".is-softer" ".block");

// Workaround, because it's not possible to reduce $font-size-base and
// we don't have font-sizes per breakpoint
@include sm {
  h1.typo,
  h1.text {
    @include font('big'); }
  h2.typo,
  h2.text {
    @include font('medium'); }
  .typo,
  .text {
    h1 {
      @include font('big'); }
    h2 {
      @include font('medium'); } } }

//--------------------------------------------------------------------------
// Special text styles
//--------------------------------------------------------------------------

.legend {
  @include font('default', 'bold');
  color: $text-color;
  text-transform: uppercase; }

//--------------------------------------------------------------------------
// Lists
//--------------------------------------------------------------------------

ol.typo {
  list-style-position: outside;
  &:not([type]) {
    list-style-type: decimal;
    &.is-lower-alpha {
      list-style-type: lower-alpha; }
    &.is-lower-roman {
      list-style-type: lower-roman; }
    &.is-upper-alpha {
      list-style-type: upper-alpha; }
    &.is-upper-roman {
      list-style-type: upper-roman; } } }
ul.typo {
  list-style: disc outside;
  ul {
    list-style-type: circle;
    margin-top: m(1);
    ul {
      list-style-type: square; } } }
li.typo {
  @extend .reset; }

ol.text {
  @extend ol.typo;
  margin: 0 0 m(2) m(4); }
ul.text {
  @extend ul.typo;
  margin: 0 0 m(2) m(4); }
li.text {
  @extend li.typo;
  padding: 0 0 0 0; }

@include reverse(ol ul li);

//--------------------------------------------------------------------------
// Quotes
//--------------------------------------------------------------------------

blockquote.typo {
  @include font('medium', 'italic');
  color: $quote-color;
  & > * {
    @extend blockquote.typo; } }

blockquote.text {
  @extend .block.text;
  @extend blockquote.typo;
  margin-left: 2em;
  padding-left: 1em;
  margin-right: 3.7em;
  border-left: .7em solid $border-color;
  > * {
    @extend blockquote.typo; } }

@include reverse(blockquote);

@include xs {
  .text {
    blockquote {
      margin-left: 1em;
      padding-left: .5em;
      margin-right: 2.2em;
      border-left: .7em solid $border-color; } } }


//--------------------------------------------------------------------------
// Code
//--------------------------------------------------------------------------

code.typo {
  font-family: $font-family-monospace;
  font-size: font-size('default') * .9;
  line-height: line-height('default');
  color: $text-color; }
pre.typo {
  @extend code.typo; }

code.text /* inline */ {
  @extend code.typo;
  padding: m(.5);
  border: 1px solid $border-color;
  border-radius: $radius;
  background-color: grey(8); }
pre.text /* block with pre-enclosure */ {
  @extend .block.text;
  @extend code.typo;
  padding: m(1);
  border: 1px solid $border-color;
  border-radius: $radius;
  background-color: grey(8);
  code {
    padding: 0;
    border: none;
    border-radius: none;
    background-color: transparent; } }

@include reverse(code pre);

//--------------------------------------------------------------------------
// Tables
//--------------------------------------------------------------------------

table.typo {
  td,
  th {
    @extend .reset;
    vertical-align: top; }
  thead {
    td,
    th {
      @extend .is-strong.typo;
      text-align: left; } }
  tfoot {
    td,
    th {
      @extend .is-strong.typo;
      text-align: left; } } }

table.text {
  @extend table.typo;
  width: 100%;
  margin: 0 0 m(2) 0;
  td,
  th {
    border: 1px solid $border-color;
    border-width: 0 0 1px;
    padding: m(1) m(1) m(1) 0; }
  thead {
    td,
    th {
      border-width: 0 0 2px; } }
  tfoot {
    td,
    th {
      border-width: 2px 0 0; } }
  tbody {
    tr {
      &:last-child {
        td,
        th {
          border-bottom-width: 0; } } } } }

@include reverse(table);

//--------------------------------------------------------------------------
// Icon-Classes
//--------------------------------------------------------------------------

[class^="icon-"],
[class*=" icon-"] {
  @include icon; }

@each $key, $value in $icons {
  .icon-#{$key}:before {
    content: $value !important; } }
