@charset "utf-8";

@import "../../styles/lib/mixins.sass";
@import "../../styles/lib/variables.sass";













































































































































































































































































































.image-zoom-elem {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: $z-index-overlay + 1;
  .menu {
    flex-shrink: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: m(2) m(2);
    z-index: $z-index-overlay + 3;
    .caption {
      margin: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: {}
      transition: opacity .4s ease-out;
      opacity: 1;
      &.fade-enter {
        opacity: 0;
        transition: opacity .4s ease-in .3s; }
      &.fade-leave-active {
        opacity: 0;
        transition: opacity .4s ease-in .3s; } } }
  .canvas {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 0 m(2) m(2) m(2);
    z-index: $z-index-overlay + 2;
    .wrapper {
      position: relative;
      flex-grow: 1;
      transition: transform .5s ease-out, opacity .3s ease-out;
      &.slide-left-enter, // moving to right
      &.slide-right-leave-active {
        opacity: 0;
        transform: translate(10%, 0);
        transition: transform .5s ease-in, opacity .3s ease-in .2s; }
      &.slide-left-leave-active, // moving to left
      &.slide-right-enter {
        opacity: 0;
        transform: translate(-10%, 0);
        transition: transform .5s ease-in, opacity .3s ease-in .2s; }
      img {
        position: absolute;
        top: 50%;
        bottom: 0;
        left: 50%;
        right: 0;
        max-width: 100%;
        max-height: 100%;
        transform: translate(-50%,-50%); } }
    .nav-image {
      position: absolute;
      top: 0;
      height: 100%;
      width: 40%;
      z-index: $z-index-overlay + 3;
      .button {
        height: 100%;
        width: 100%; }
      &.prev {
        left: m(2);
        .button {
          justify-content: flex-start; } }
      &.next {
        right: m(2);
        .button {
          justify-content: flex-end; } } } }
  .thumbs {
    flex-shrink: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: m(2);
    z-index: $z-index-overlay + 3;
    .sideshow-elem-carousel {
      .VueCarousel-wrapper {
        .VueCarousel-inner {
          .VueCarousel-slide {
            .frame {
              display: flex;
              flex-direction: column;
              // width and padding are set by style
              cursor: pointer; }
            .marker {
              height: 3px;
              margin-top: 2px;
              background-color: transparent; }
            &.is-current {
              .frame {
                cursor: default;
                .marker {
                  background-color: white(); } } } } } }
      .VueCarousel-pagination,
      .VueCarousel-navigation {
        display: none !important; } }
    .nav-thumbs {
      flex-shrink: 1;
      display: flex;
      flex-direction: column;
      justify-content: center; } }
  &.is-single {
    .canvas {
      .nav-image {
        display: none; } }
    .thumbs {
      display: none; } }
  &.is-black {
    background-color: black();
    .menu {
      .caption {
        color: white(); } } }
  &.is-grey {
    background-color: grey(7);
    .menu {
      .caption {
        color: $text-color; } } }
  &.is-white {
    background-color: white();
    .menu {
      .caption {
        color: $text-color; } } } }

@include xs {
  .image-zoom-elem {
    .canvas {
      .nav {
        display: none; }
      .button-elem {} }
    .thumbs {
      display: none; } } }
