@charset "utf-8";

@import "../../styles/lib/mixins.sass";
@import "../../styles/lib/variables.sass";



























































































































.link-elem {
  cursor: pointer; }
