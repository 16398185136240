html {
  margin: 0;
  padding: 0;
  background-color: $body-color;
  font-size: font-size-base();
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  min-width: 300px;
  overflow-x: hidden;
  overflow-y: scroll;
  text-rendering: optimizeLegibility;
  text-size-adjust: 100%;
  box-sizing: border-box; }

body {
  margin: 0;
  padding: 0;
  background-color: $body-color;
  @include font('regular', 'default');
  color: $text-color; }

article,
aside,
figure,
footer,
header,
hgroup,
section {
  display: block; }

iframe {
  height: auto;
  max-width: 100%;
  margin: 0;
  padding: 0;
  border: 0; }

* {
  &,
  &::before,
  &::after {
    box-sizing: inherit; } }

h1,
h2,
h3,
h4,
h5,
h6,
p,
dl,
dt,
dd,
blockquote,
figure {
  margin: 0;
  padding: 0;
  font-weight: normal;
  font-size: 100%; }

a {
  color: $text-color;
  cursor: pointer;
  text-decoration: none;
  outline: none;
  strong {
    color: currentColor; }
  &:hover {
    color: $text-color; } }

small {
  @include font('small'); }

span {
  font-style: inherit;
  font-weight: inherit; }

b,
strong,
i,
em {
  @include font('regular');
  font-weight: regular; }

code,
pre {
  margin: 0;
  padding: 0;
  overflow-y: scroll;
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: auto; }

pre {
  white-space: pre-wrap;
  word-break: keep-all; }

hr {
  margin: 0;
  padding: 0;
  background-color: $text-color;
  border: none;
  display: block;
  height: 1px; }

img,
embed,
object,
video {
  height: auto;
  max-width: 100%; }

audio {
  max-width: 100%; }

ul {
  list-style: none;
  margin: 0;
  padding: 0; }

ol {
  margin: 0;
  padding: 0; }

li {
  margin: 0;
  padding: 0; }

table {
  border-collapse: collapse;
  border-spacing: 0;
  td,
  th {
    padding: 0;
    text-align: left;
    vertical-align: top; } }

button,
input,
select,
textarea {
  margin: 0;
  @include font('regular');
  outline: none; }

input[type="checkbox"],
input[type="radio"] {
  vertical-align: baseline;
  outline: none; }

fieldset {
  margin: 0;
  padding: 0;
  border: none; }

legend {
  margin: 0;
  padding: 0; }

@include md {
  html {
    font-size: font-size-base('md'); } }

@include sm {
  html {
    font-size: font-size-base('sm'); } }

@include xs {
  html {
    font-size: font-size-base('xs'); } }
