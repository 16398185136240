@charset "utf-8";

@import "../../styles/lib/mixins.sass";
@import "../../styles/lib/variables.sass";
































.header-segment {
  background-color: $primary-color;
  .container {
    position: relative;
    padding-top: 0;
    padding-bottom: 0;
    background-color: $primary-color;
    .link-home {
      display: inline-block;
      height: 100%; }
    .logo {
      display: inline-block;
      padding-top: 35px;
      padding-bottom: 35px;
      img {
        height: 110px;
        cursor: pointer; } }
    .navigation-elem {
      position: absolute;
      top: 28px;
      left: content-padding();
      right: content-padding();
      margin-top: 0; }
    .tool {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-end;
      bottom: 35px;
      left: content-padding();
      right: content-padding();
      .division {
        color: white();
        font-size: 1.25rem;
        line-height: 1.4; }
      .toolname {
        padding-bottom: 10px;
        color: white();
        font-size: 1.75rem;
        line-height: 1; } } } }

@include md {
  .header-segment {
    .container {
      .navigation-elem {
        left: content-padding('md');
        right: content-padding('md'); }
      .tool {
        left: content-padding('md');
        right: content-padding('md'); } } } }

@include sm {
  .header-segment {
    .container {
      .navigation-elem {
        left: auto;
        top: 29px;
        right: content-padding('sm'); }
      .logo {
        padding-top: 29px;
        padding-bottom: 29px;
        img {
          height: 90px; } }
      .tool {
        display: none; } } } }

@include xs {
  .header-segment {
    .container {
      .navigation-elem {
        left: auto;
        top: auto;
        right: content-padding('xs');
        bottom: 7px; }
      .logo {
        padding-top: 62px;
        padding-bottom: 11px;
        img {
          height: 32px; } } } } }

