@charset "utf-8";

@import "../../styles/lib/mixins.sass";
@import "../../styles/lib/variables.sass";













































.cookie-segment {
  position: fixed;
  display: flex;
  flex-direction: column;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: m(2);
  background-color: black(.7);
  transition: all .8s ease;
  opacity: 0;
  transform: translateY(100%);
  z-index: $z-index-modal + 10;
  p {
    @include font('default');
    color: white(); }
  .buttons {
    text-align: right; }
  &.is-visible {
    opacity: 1;
    transform: translateY(0); } }

@include xs {
  .cookie-segment {
    .buttons {
      text-align: center; } } }
