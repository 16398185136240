@charset "utf-8";

@import "../../styles/lib/mixins.sass";
@import "../../styles/lib/variables.sass";














































































































































































































































































































































































































































$dot-color: grey(7);
$dot-active-color: $orange;
$dot-width: 14px;
$dot-height: 14px;

.images-elem {
  position: relative;
  display: block;
  overflow: hidden;
  margin-top: - (col-gap()/2);
  margin-bottom: - (col-gap()/2);
  .slide {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: (col-gap()/2) !important;
    justify-content: center;
    align-items: center;
    line-height: 0;
    .frame {}
    .caption {
      padding: .3rem 0;
      line-height: line-height('default'); } }
  .images-elem-carousel { // unique name, otherwise image-zoom-elem will inherit
    .VueCarousel {
      margin: 0;
      padding: 0;
      .VueCarousel-wrapper {
        .VueCarousel-inner {
          margin-left: -(col-gap() / 2);
          margin-right: -(col-gap() / 2); } } }
    .navigation {
      display: none;
      position: absolute;
      top: col-gap()/2;
      bottom: col-gap()/2; // col-gap() + (1.5 * $dot-height)
      width: m(5);
      .button {
        height: 100%;
        width: 100%;
        &.is-disabled {
 } }          //display: none
      &.prev {
        left: 0;
        .button {
          justify-content: flex-start; } }
      &.next {
        right: 0;
        .button {
          justify-content: flex-end; } } } }
  .images-elem-pagination {
    position: relative;
    text-align: center;
    line-height: 0;
    font-size: 0;
    overflow: hidden;
    .pagination {
      position: absolute;
      top: -9999px; // invisible, but in DOM, don't change, set to 0 if visible
      padding-top: $dot-height / 2; // pixel required, @see navigation bottom
      padding-bottom: m(1);
      display: inline-flex;
      .dot {
        flex-shrink: 0;
        margin: 0 3px;
        width: $dot-width;
        height: $dot-height;
        background-color: $dot-color;
        cursor: pointer;
        border-radius: 7px;
        &:first-child {
          margin-left: 0; }
        &:last-child {
          margin-right: 0; }
        &:not(.is-active):hover {
          background-color: darken($dot-color, 20%); }
        &.is-active {
          background-color: $dot-active-color;
          cursor: default; } } } }
  .images-elem-legend {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  &.has-navigation {
    .images-elem-carousel {
      .navigation {
        display: block; } } }
  &.has-pagination {
    .images-elem-pagination {
      .pagination {
        position: relative;
        top: 0; } } }
  &.is-pagination-inside {
    .images-elem-carousel {
      .navigation {
        bottom: col-gap()/2; } }
    .images-elem-pagination {
      width: 100%;
      transform: translateY(- m(5));
      margin-bottom: - $dot-height;
      .pagination {
        padding: 0 m(1);
        .dot {
          background-color: white();
          opacity: .6;
          &:not(.is-active):hover {
            background-color: white();
            opacity: 1; }
          &.is-active {
            background-color: $dot-active-color;
            opacity: 1; } } } } }
  &.has-zoom {
    .slide {
      .frame {
        position: relative;
        cursor: pointer;
        overflow: hidden;
        &:after {
          display: flex;
          justify-content: center;
          align-items: center;
          content: map-get($icons, 'fullscreen');
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          font-family: $font-family-icons;
          font-size: font-size('big');
          color: white();
          background-color: black();
          opacity: 0;
          transition: opacity .6s ease-out .4s; }
        picture {
          img {
            transition: all .6s ease-out .4s; } }
        &:hover {
          &:after {
            opacity: .4; }
          picture {
            img {
              transform: scale(1.1); } } } } }
    .images-elem-carousel {
      position: relative; } }
  .image-zoom-elem {
    transition: opacity .6s ease-in;
    opacity: 1;
    &.fade-enter {
      opacity: 0; }
    &.fade-leave-active {
      opacity: 0; } } }

@include md {
  .images-elem {
    margin-top: - (col-gap('md')/2);
    margin-bottom: - (col-gap('md')/2);
    .slide {
      padding: (col-gap('md')/2) !important; }
    .images-elem-carousel {
      .VueCarousel {
        .VueCarousel-inner {
          margin-left: -(col-gap('md') / 2);
          margin-right: -(col-gap('md') / 2); } }
      .navigation {
        top: col-gap('md')/2;
        bottom: col-gap('md') + (1.5 * $dot-height); } }
    &.is-pagination-inside {
      .images-elem-carousel {
        .navigation {
          bottom: col-gap('md')/2; } } } } }

@include sm {
  .images-elem {
    margin-top: - (col-gap('sm')/2);
    margin-bottom: - (col-gap('sm')/2);
    .slide {
      padding: (col-gap('sm')/2) !important; }
    .images-elem-carousel {
      .VueCarousel {
        .VueCarousel-inner {
          margin-left: -(col-gap('sm') / 2);
          margin-right: -(col-gap('sm') / 2); } }
      .navigation {
        top: col-gap('sm')/2;
        bottom: col-gap('sm') + (1.5 * $dot-height); } }
    &.is-pagination-inside {
      .images-elem-carousel {
        .navigation {
          bottom: col-gap('sm')/2; } } } } }

@include xs {
  .images-elem {
    margin-top: - (col-gap('xs')/2);
    margin-bottom: - (col-gap('xs')/2);
    .slide {
      padding: (col-gap('xs')/2) !important; }
    .images-elem-carousel {
      .VueCarousel {
        .VueCarousel-inner {
          margin-left: -(col-gap('xs') / 2);
          margin-right: -(col-gap('xs') / 2); } }
      .navigation {
        top: col-gap('xs')/2;
        bottom: col-gap('xs') + (1.5 * $dot-height); } }
    .images-elem-legend {
      flex-direction: column; }
    &.is-pagination-inside {
      .images-elem-carousel {
        .navigation {
          bottom: col-gap('xs')/2; } } } } }
