//--------------------------------------------------------------------------
// Mixins
//--------------------------------------------------------------------------

@mixin input {
  -webkit-appearance: none;
  -moz-appearance: none;
  max-width: 100%;
  width: 100%;
  padding: $form-vertical-padding $form-input-indent;
  font-size: $form-font-size;
  line-height: $form-line-height;
  color: $form-color;
  border: 1px solid $form-border-color;
  box-shadow: none;
  outline: none;
  border-radius: $form-border-radius;
  background-color: $form-background-color;
  @include placeholder {
    color: $form-placeholder-color; }
  &:focus {
    border-color: $form-control-color;
    box-shadow: 0 0 0 0.125em rgba($form-control-color, 0.25); } }

@mixin disabled {
  @include unselectable;
  color: $form-disabled-color;
  background-color: $form-disabled-background-color;
  cursor: default;
  &:focus {
    border-color: $form-border-color;
    box-shadow: none; } }

@mixin error {
  padding: ($form-vertical-padding  - 1px) $form-input-indent;
  border: 2px solid $form-error-color;
  &:focus {
    border-color: $form-error-color;
    box-shadow: 0 0 0 0.125em rgba($form-error-color, 0.25); } }

//--------------------------------------------------------------------------
// Styles
//--------------------------------------------------------------------------

.label {
  display: inline-flex;
  align-items: flex-start;
  //no margin-right in col childs
  padding: 3px 0;
  font-size: $form-font-size;
  line-height: $form-line-height;
  &.has-error {
    color: $form-error-color; }
  &.is-hidden {
    display: none; }
  &.is-visible {
    display: inline-flex; } }

.control {
  position: relative;
  display: flex;
  align-items: flex-start;
  margin-bottom: (font-size('default') * line-height('default') + m(1)); // space for help, always for unique look
  &.has-help {
    .help {
      position: absolute;
      display: flex;
      bottom: (font-size('default') * line-height('default') + m(1)) * -1;
      left: 0;
      right: 0;
      @include font('default');
      color: grey(5);
      @include ellipsis; } }

  &.text {
    input {
      @include input; }
    &.is-disabled {
      input {
        @include disabled; } }
    &:not(.is-disabled) {
      input {
        transition: color .6s ease, background-color .6s ease; }
      &.is-blinking {
        input {
          color: white();
          background-color: $primary-color; } }
      &.has-error {
        input {
          @include error; } } } }

  &.textarea {
    textarea {
      @include input;
      resize: vertical;
      &:not([rows]) {
        height: $form-textarea-height;
        min-height: $form-textarea-height;
        max-height: $form-textarea-height * 3; }
      &[rows] {
        height: initial; }
      &:focus {
        border-color: $form-control-color;
        box-shadow: 0 0 0 0.125em rgba($form-control-color, 0.25); } }
    &.is-disabled {
      textarea {
        @include disabled; } }
    &:not(.is-disabled) {
      &.has-error {
        textarea {
          @include error; } } }
    &.has-fixed-size {
      textarea {
        resize: none; } }
    &.is-small {
      textarea {
        &:not([rows]) {
          height: $form-textarea-height * .5;
          min-height: $form-textarea-height * .5;
          max-height: $form-textarea-height * 1.5; } } }
    &.is-medium {
      textarea {
        &:not([rows]) {
          height: $form-textarea-height * 1.5;
          min-height: $form-textarea-height * 1.5;
          max-height: $form-textarea-height * 4.5; } } }
    &.is-big {
      textarea {
        &:not([rows]) {
          height: $form-textarea-height * 2;
          min-height: $form-textarea-height * 2;
          max-height: $form-textarea-height * 6; } } } }

  &.select {
    @include input;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .selected-option {
      flex: 1;
      @include ellipsis; }
    .dropdown-icon {
      height: 0.625em;
      width: 0.625em;
      margin-right: 2px;
      margin-top: -3px;
      margin-left: $form-input-indent;
      border: 4px solid $form-control-color;
      border-right: 0;
      border-top: 0;
      border-radius: 2px;
      pointer-events: none;
      transform: rotate(-45deg);
      transform-origin: center;
      content: " ";
      z-index: 4; }
    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      padding: 0;
      opacity: 0;
      filter: alpha(opacity=0);
      border: none;
      font-size: $form-font-size;
      visibility: visible;
      cursor: pointer;
      &::-ms-expand {
        display: none; } }
    &.is-disabled {
      @include disabled;
      select {
        cursor: default; }
      .dropdown-icon {
        border-color: $form-disabled-color; } }
    &:not(.is-disabled) {
      transition: color .6s ease, background-color .6s ease;
      &.is-blinking {
        color: white();
        background-color: $primary-color;
        .dropdown-icon {
          border-color: white(); } }
      &.has-error {
        @include error;
        .dropdown-icon {
          border-color: $form-error-color; } } } }

  &.options {
    @include input;
    position: relative;
    display: flex;
    padding: 0;
    .option {
      flex: 1;
      padding: $form-vertical-padding $form-input-indent;
      font-size: $form-font-size * .833;
      line-height: $form-line-height * 1.2;
      text-align: center;
      background-color: transparent;
      z-index: $z-index-base;
      cursor: pointer;
      transition: color .2s ease .3s, font-size 0 ease .3s, line-height 0 ease .3s;
      @include ellipsis;
      &.is-selected {
        font-size: $form-font-size;
        line-height: $form-line-height;
        color: white();
        cursor: default; }
      &.is-disabled {
        color: $form-disabled-color;
        cursor: default; } }
    &.has-icons {
      .option {
        line-height: 0;
        .svg-elem {
          height: $form-font-size * $form-line-height;
          transition: fill .2s ease .3s; }
        &.is-selected {
          .svg-elem {
            fill: white(); } }
        &.is-disabled {
          .svg-elem {
            fill: $form-disabled-color; } } } }
    &.has-help {
      .help {
        span {
          text-align: center;
          transition: color .2s ease .3s;
          &.is-selected {
            color: $primary-color; }
          &.is-disabled {
            color: $form-disabled-color; } } } }
    .slider {
      position: absolute;
      display: inline-block;
      height: 100%;
      left: 0%;
      background-color: $form-control-color;
      border: 2px solid $form-background-color;
      border-left-width: 3px;
      transition: left .5s ease;
      z-index: $z-index-base - 1;
      &.is-first {
        border-top-left-radius: $form-border-radius;
        border-bottom-left-radius: $form-border-radius;
        border-left-width: 2px; }
      &.is-last {
        border-top-right-radius: $form-border-radius;
        border-bottom-right-radius: $form-border-radius; } }
    &.is-disabled {
      @include disabled;
      .option {
        cursor: default;
        &.is-selected {
          color: $form-color; } }
      .slider {
        background-color: darken($form-disabled-background-color, 10%);
        border: none; } }
    &:not(.is-disabled) {
      &.has-error {
        border: 2px solid $form-error-color;
        .option {
          padding: 1px $form-input-indent; } } } }

  &.checkbox {
    display: flex;
    align-items: flex-start;
    padding: 2px 0;
    font-size: $form-font-size;
    line-height: $form-line-height;
    color: $form-color;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    cursor: pointer;
    input {
      $margin-vertical: (($form-font-size * $form-line-height) - $form-font-size) / 2;
      position: relative;
      flex-shrink: 0;
      width: $form-font-size;
      height: $form-font-size;
      margin: $margin-vertical $form-input-indent $margin-vertical 0;
      border: 1px solid $form-border-color;
      border-radius: $form-border-radius;
      background-color: $form-background-color;
      transform: translateY(1px);
      -webkit-appearance: none;
      -moz-appearance: none;
      outline: none;
      cursor: pointer;
      &:checked {
        &:after {
          position: absolute;
          display: block;
          top: 50%;
          height: 0.625em;
          width: 1.25em;
          margin-top: -0.4em;
          margin-left: 0.25em;
          border: 4px solid $form-control-color;
          border-right: 0;
          border-top: 0;
          border-radius: 2px;
          pointer-events: none;
          transform: rotate(-45deg);
          transform-origin: center;
          content: " "; } } }
    &.is-inline {
      display: inline-flex;
      margin-right: $form-input-indent * 3; }
    &.is-disabled {
      input {
        background-color: $form-disabled-background-color;
        &:checked {
          &:after {
            border-color: $form-disabled-color; } } } }
    &.has-error:not(.is-disabled) {
      color: $warning-color;
      input {
        border-color: $form-error-color;
        background-color: $form-error-color;
        &:checked {
          &:after {
            border-color: white(); } } }
      p {
        color: $warning-color;
        a {
          color: $warning-color;
          text-decoration: underline; } } } }

  &.radio {
    display: flex;
    flex-direction: column;
    .option {
      display: flex;
      align-items: flex-start;
      padding: 2px 0;
      margin-right: $form-input-indent;
      font-size: $form-font-size;
      line-height: $form-line-height;
      border-top: 1px solid transparent;
      border-bottom: 1px solid transparent;
      cursor: pointer;
      input {
        $margin-vertical: (($form-font-size * $form-line-height) - $form-font-size) / 2;
        position: relative;
        flex-shrink: 0;
        width: $form-font-size;
        height: $form-font-size;
        margin: $margin-vertical $form-input-indent 0 0;
        border: 1px solid $form-border-color;
        background-color: $form-background-color;
        border-radius: $radius-rounded;
        transform: translateY(1px);
        -webkit-appearance: none;
        -moz-appearance: none;
        outline: none;
        &:checked {
          &:after {
            position: absolute;
            display: block;
            top: 1px;
            left: 1px;
            bottom: 1px;
            right: 1px;
            border: 1px solid white();
            background-color: $form-control-color;
            border-radius: $radius-rounded;
            pointer-events: none;
            content: " "; } } } }
    &.is-inline {
      display: inline-flex;
      flex-direction: row;
      .option {
        margin-right: $form-input-indent * 3; } }
    &.is-disabled {
      .option {
        cursor: default;
        input {
          background-color: $form-disabled-background-color;
          &:checked {
            &:after {
              background-color: $form-disabled-color; } } } } }
    &.has-error:not(.is-disabled) {
      .option {
        input {
          border-color: $form-error-color;
          background-color: $form-error-color;
          &:checked {
            &:after {
              border: 2px solid white();
              background-color: $form-error-color; } } } } } } }
